<template>
  <div class="resize">
    <!--    <label class="resize-row">{{ $t('attach') }}</label>-->
    <div class="l-col">
      <base-input v-model="name" size="medium" :label="$t('attach')" @input="onChange"></base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'RenameRouter',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    instance: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: this.instance,
    };
  },
  computed: {},
  methods: {
    onChange() {
      this.$emit('instance', this.name);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Имя роутера:",
    "sure": {
    "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
